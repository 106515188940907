<template>
  <RouterLink
    v-if="!isExternalLink"
    :to="location"
    :class="[routeNameClass, isHeaderLink ? 'hover:border-brand-border pb-0 md:pb-1 md:text-sm lg:text-base' : 'hover:border-white md:text-xl']"
    class="transition-colors duration-300 border-b-2 inline"
  >
    <slot />
  </RouterLink>

  <a
    v-else
    :href="to"
    target="_blank"
    :class="[routeNameClass, isHeaderLink ? 'hover:border-brand-border pb-1 md:text-sm lg:text-base' : 'hover:border-white md:text-xl']"
    class="transition-colors duration-300 border-b-2 inline cursor-pointer"
  >
    <slot />
  </a>
</template>

<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  to: {
    type: String,
    required: true,
  },

  isExternalLink: {
    type: Boolean,
    default: false,
  },

  isHeaderLink: {
    type: Boolean,
    default: false,
  },

  activateLink: {
    type: Boolean,
    default: true,
  },

  profileSlug: {
    type: String,
    default: '',
  },
})

const route = useRoute()

const routeNameClass = computed(() => route.name === props.to && props.activateLink ? (props.isHeaderLink ? 'border-brand-border' : 'border-white') : 'border-transparent')

const location = computed(() => {
  if ( props.profileSlug === '' ) {
    return { name: props.to }
  } else {
    return { name: props.to, params: { profileSlug: props.profileSlug } }
  }
})
</script>
