const ProfileEdit = () => import('#pages/profile/profile-edit.vue')
const ProfileEarnings = () => import('#pages/profile/profile-earnings.vue')
const ProfileIndex = () => import('#pages/profile/profile-index.vue')
const ProfileDrops = () => import('#pages/profile/profile-drops.vue')
const ProfileCollected = () => import('#pages/profile/profile-collected.vue')
const ProfileCollectors = () => import('#pages/profile/profile-collectors.vue')
const ProfileFollowers = () => import('#pages/profile/profile-followers.vue')
const ProfileFollowing = () => import('#pages/profile/profile-following.vue')
const ProfileAnalytics = () => import('#pages/profile/profile-analytics.vue')
const SharedPost = () => import('#pages/profile/community-hub-shared-post.vue')
const Drop = () => import('#pages/profile/drop/drop.vue')
const DropAnalytics = () => import('#pages/profile/drop/drop-analytics.vue')
const DropToken = () => import('#pages/profile/drop-token/drop-token.vue')
const SingleDesignNftCreate = () => import('#pages/single-design-nft/single-design-nft-create-form.vue')
const SingleDesignNftEdit = () => import('#pages/single-design-nft/single-design-nft-edit-form.vue')
const AddPerk = () => import('#pages/single-design-nft/add-perk.vue')
const UploadPerkFile = () => import('#pages/single-design-nft/upload-perk-file.vue')

import useLogin from '#composables/use-login'
import useFeatureFlag from '#composables/use-feature-flag'
import useAccount from '#composables/use-account'

import type { RouteLocationNormalized, NavigationGuard, RouteRecordName } from 'vue-router'

const redirectToMyProfileGuard: NavigationGuard = async (to: RouteLocationNormalized) => {
  if (to.name && (to.name as string).startsWith('me')) {
    const { id, account, isAuthReady } = useLogin()

    await isAuthReady()

    const profileSlug = account.value?.slug

    if (!id.value) {
      return ({ name: 'home' as RouteRecordName })
    }

    switch (to.name) {
      case 'me-profile':
        return `/${profileSlug}`
      case 'me-drops':
        return `/${profileSlug}/drops`
      case 'me-collected':
        return `/${profileSlug}/collected`
      case 'me-collectors':
        return `/${profileSlug}/collectors`
      case 'me-followers':
        return `/${profileSlug}/followers`
      case 'me-following':
        return `/${profileSlug}/following`
      case 'me-analytics':
        return `/${profileSlug}/analytics`
      case 'me-edit':
        return `/${profileSlug}/edit`
      case 'me-earnings':
        return `/${profileSlug}/earnings`
    }
  }
}

const redirectToDropsGuard: NavigationGuard = async (to: RouteLocationNormalized) => {
  const { isAuthReady, account, isAdmin } = useLogin()

  await isAuthReady()

  const profileSlug = account.value?.slug
  const toSlug = (to.params.profileSlug as string).toLowerCase()

  if ((toSlug !== profileSlug) && !isAdmin.value) {
    return `/${toSlug}/drops`
  }
}

const myProfileGuard: NavigationGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { account, isAuthReady, isAdmin } = useLogin()

  await isAuthReady()

  const profileSlug = account.value?.slug
  const toSlug = (to.params.profileSlug as string).toLowerCase()

  // if users slug does not match profile slug, redirect to profile
  if (profileSlug !== toSlug && !isAdmin.value) {
    return `/${profileSlug}`
  }
}

const earningsGuard: NavigationGuard = async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
  const { account, isAuthReady } = useLogin()

  await isAuthReady()

  const isCreator = account.value?.roles.includes('creator')
  const isEarningsRoute = to.name === 'me-earnings' || to.name === 'earnings'

  // if users slug does not match profile slug, redirect to profile
  if (isEarningsRoute && !isCreator) {
    return `/`
  }
}

const createDropGuard: NavigationGuard = async () => {
  const { isAuthReady } = useLogin()
  const { isAdmin, verified, profileUsername } = useAccount()
  const { isFlagEnabled } = useFeatureFlag()

  await isAuthReady()

  // redirects the user to their drops page if the feature flag is on and if the user is not verified or an admin
  if (isFlagEnabled('VITE_FEATURE_REQUIREMENTS') && !verified.value && !isAdmin.value) {
    return `/${profileUsername}/drops`
  }
}

export default [
  {
    path: '/:profileSlug',
    name: 'profile',
    component: ProfileIndex,
    beforeEnter: redirectToMyProfileGuard,
    redirect: (route: RouteLocationNormalized) => ({ name: 'profile-drops', params: { profileSlug: (route.params.profileSlug as string).toLowerCase() } }),
    meta: {
      title: 'profile',
    },
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
    children: [
      {
        path: 'drops',
        name: 'profile-drops',
        props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
        component: ProfileDrops,
      },
      {
        path: 'collected',
        name: 'profile-collected',
        props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
        component: ProfileCollected,
      },
      {
        path: 'collectors',
        name: 'profile-collectors',
        props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
        component: ProfileCollectors,
        beforeEnter: redirectToDropsGuard,
      },
      {
        path: 'followers',
        name: 'profile-followers',
        props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
        component: ProfileFollowers,
      },
      {
        path: 'following',
        name: 'profile-following',
        props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
        component: ProfileFollowing,
      },
    ],
  },
  {
    path: '/:profileSlug/edit',
    name: 'profile-edit',
    component: ProfileEdit,
    meta: {
      title: 'edit profile',
      requiresAuthentication: true,
    },
  },
  {
    path: '/:profileSlug/analytics',
    name: 'profile-analytics',
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
    component: ProfileAnalytics,
    beforeEnter: myProfileGuard,
    meta: {
      title: 'Analytics dashboard',
      requiresAuthentication: true,
    },
  },
  {
    // The will eventually be added as child route of /:profileSlug so we can
    // load it in the routerview of the profile page
    path: '/:profileSlug/post/:postId',
    name: 'sharedPost',
    component: SharedPost,
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase(), postId: route.params.postId as string }),
  },
  {
    path: '/:profileSlug/drop/create-drop',
    name: 'drop-create',
    props: (route: RouteLocationNormalized) => ({ communityId: (route.params.profileSlug as string).toLowerCase() }),
    component: SingleDesignNftCreate,
    beforeEnter: [myProfileGuard, createDropGuard],
    meta: {
      title: 'Create a new drop',
    },
  },
  {
    path: '/:profileSlug/drop/:dropId',
    name: 'drop',
    component: Drop,
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
    meta: {
      title: 'Drop',
    },
  },
  {
    path: '/:profileSlug/drop/:dropId/analytics',
    name: 'drop-analytics',
    component: DropAnalytics,
    beforeEnter: [myProfileGuard],
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase() }),
    meta: {
      title: 'Drop analytics',
    },
  },
  {
    path: '/:profileSlug/drop/:dropId/edit',
    name: 'drop-edit',
    component: SingleDesignNftEdit,
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase(), benefitId: route.params.dropId as string }),
    beforeEnter: redirectToDropsGuard,
    meta: {
      title: 'Edit your drop',
    },
  },
  {
    path: '/:profileSlug/drop/:dropId/add-perk',
    name: 'drop-add-perk',
    component: AddPerk,
    meta: {
      title: 'Add a new drop perk',
    },
  },
  {
    path: '/:profileSlug/drop/:dropId/upload-perk-file',
    name: 'drop-upload-perk-file',
    component: UploadPerkFile,
    meta: {
      title: 'Upload a new drop perk file',
    },
  },
  {
    path: '/:profileSlug/drop/:dropId/:tokenId',
    name: 'drop-token',
    props: (route: RouteLocationNormalized) => ({ profileSlug: (route.params.profileSlug as string).toLowerCase(), benefitId: route.params.dropId as string, tokenId: route.params.tokenId }),
    component: DropToken,
    meta: {
      title: 'Drop token',
    },
  },
  {
    path: '/:profileSlug/earnings',
    name: 'earnings',
    beforeEnter: [myProfileGuard],
    component: ProfileEarnings,
    meta: {
      title: 'earnings',
    },
  },

  // me routes
  {
    path: '/me',
    name: 'me-profile',
    component: ProfileIndex,
    beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
    props: () => {
      const { account } = useLogin()

      const profileSlug = account.value?.communities.filter(community => community.role === 'owner')[0]?.community.slug

      return {
        profileSlug: ((profileSlug) || '').toLowerCase(),
        isOwnProfile: true,
      }
    },
    meta: {
      title: 'my profile',
      requiresAuthentication: true,
    },
    children: [
      {
        path: 'drops',
        name: 'me-drops',
        props: () => {
          const { account } = useLogin()

          const profileSlug = account.value?.slug

          return {
            profileSlug: ((profileSlug) || '').toLowerCase(),
            isOwnProfile: true,
          }
        },
        meta: {
          requiresAuthentication: true,
        },
        beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
        component: ProfileDrops,
      },
      {
        path: 'collected',
        name: 'me-collected',
        props: () => {
          const { account } = useLogin()

          const profileSlug = account.value?.slug
          return {
            profileSlug: ((profileSlug) || '').toLowerCase(),
            isOwnProfile: true,
          }
        },
        meta: {
          requiresAuthentication: true,
        },
        beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
        component: ProfileCollected,
      },
      {
        path: 'collectors',
        name: 'me-collectors',
        props: () => {
          const { account } = useLogin()

          const profileSlug = account.value?.slug

          return {
            profileSlug: ((profileSlug) || '').toLowerCase(),
            isOwnProfile: true,
          }
        },
        meta: {
          requiresAuthentication: true,
        },
        beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
        component: ProfileCollectors,
      },
      {
        path: 'followers',
        name: 'me-followers',
        props: () => {
          const { account } = useLogin()

          const profileSlug = account.value?.slug

          return {
            profileSlug: ((profileSlug) || '').toLowerCase(),
            isOwnProfile: true,
          }
        },
        meta: {
          title: 'collectors',
          requiresAuthentication: true,
        },
        beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
        component: ProfileFollowers,
      },
      {
        path: 'analytics',
        name: 'me-analytics',
        props: () => {
          const { account } = useLogin()

          const profileSlug = account.value?.slug

          return {
            profileSlug: ((profileSlug) || '').toLowerCase(),
            isOwnProfile: true,
          }
        },
        meta: {
          title: 'analytics',
          requiresAuthentication: true,
        },
        beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
        component: ProfileAnalytics,
      },
    ],
  },
  {
    path: '/me/following',
    name: 'me-following',
    props: () => {
      const { account } = useLogin()

      const profileSlug = account.value?.slug

      return {
        profileSlug: ((profileSlug) || '').toLowerCase(),
        isOwnProfile: true,
      }
    },
    beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
    meta: {
      title: 'rewards',
      requiresAuthentication: true,
    },
    component: ProfileFollowing,
  },
  {
    path: '/me/edit',
    name: 'me-edit',
    meta: {
      title: 'edit profile',
      requiresAuthentication: true,
    },
    beforeEnter: [redirectToMyProfileGuard, myProfileGuard],
    component: ProfileEdit,
  },
  {
    path: '/me/earnings',
    name: 'me-earnings',
    props: () => {
      const { id, account } = useLogin()

      return {
        id: (id.value || '').toLowerCase(),
        profileSlug: (account.value.slug || '').toLowerCase(),
      }
    },
    beforeEnter: [redirectToMyProfileGuard, myProfileGuard, earningsGuard],
    component: ProfileEarnings,
    meta: {
      title: 'earnings',
      requiresAuthentication: true,
    },
  },
]
