
<template>
  <svg
    width="16"
    height="14"
    viewBox="0 0 16 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.375 0.25H13.625V13.75H11.9736V7.56613H11.9574C11.7749 5.85706 10.0728 4.51773 8 4.51773C5.92721 4.51773 4.22509 5.85706 4.04256 7.56613H4.02638V13.75H2.375V0.25Z"
      fill="white"
    />
    <path
      d="M0.125 1.375L0.766451 3.422H1.3092V11.703C1.0367 11.703 0.815788 11.9113 0.815788 12.1682V12.7265H0.717113C0.444604 12.7265 0.223674 12.9348 0.223674 13.1917V13.75H5.75V13.1917C5.75 12.9348 5.52909 12.7265 5.25658 12.7265H5.15791V12.1682C5.15791 11.9113 4.93698 11.703 4.66447 11.703H4.07238V1.375H0.125Z"
      fill="white"
    />
    <path
      d="M11.3355 11.703C11.063 11.703 10.8421 11.9113 10.8421 12.1682V12.7265H10.7434C10.4709 12.7265 10.25 12.9348 10.25 13.1917V13.75H15.7763V13.1917C15.7763 12.9348 15.5554 12.7265 15.2829 12.7265H15.1842V12.1682C15.1842 11.9113 14.9633 11.703 14.6908 11.703V3.422H15.2336L15.875 1.375H11.9276V11.703H11.3355Z"
      fill="white"
    />
  </svg>
</template>
