<template>
  <div
    class="fixed transition-bottom duration-300 ease-out z-10"
    :class="showAddPostButton ? 'bottom-0': '-bottom-40' "
  >
    <div class="absolute w-14 h-14 lg:w-16 lg:h-16 right-3 lg:right-5 bottom-3 lg:bottom-5">
      <GlobalButton
        class="group w-14 h-14 lg:w-16 lg:h-16 bg-black border-slate-800 md:bg-white min-[1800px]:bg-black"
        size="sm"
        @click.prevent="handleCreatePost"
      >
        <span>
          <Icon
            icon="PlusIcon"
            class="text-white md:text-black min-[1800px]:text-white w-8 h-8"
          />
        </span>
      </GlobalButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue'

import GlobalButton from '#components/global/global-button'
import Icon from '#components/icon.vue'

const emit = defineEmits(['open-create-post-modal'])

const showAddPostButton = ref(false)

const handleCreatePost = () => {
  emit('open-create-post-modal')
}
const handleScroll = () => {
  showAddPostButton.value = window.scrollY > 200
}

onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})
</script>
